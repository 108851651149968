





import { PersonalKey } from '@/api/personal-keys/personal-key.model';
import {
  FormElement,
  FormElementOptions,
  FormElementType,
} from '@/shared/types/form-element.class';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Asset } from '../api/assets/asset.model';
import { Project } from '../api/projects/project.model';
import { User } from '../api/users/user.class';
import { Filter, FilterOperation } from '../shared/types/filter.class';
import BaseForm from './BaseForm.vue';

const assetsModule = namespace('assets');
const projectsModule = namespace('projects');
const usersModule = namespace('users');
const appModule = namespace('app');

// The @Component decorator indicates the class is a Vue component
@Component({
  components: {
    BaseForm,
  },
})
export default class PersonalKeyForm extends Vue {
  @Prop()
  personalKey!: PersonalKey;

  @assetsModule.Getter('all')
  assets!: Asset[];

  @projectsModule.Getter('all')
  projects!: Project[];

  @usersModule.Getter('all')
  users!: User[];

  @usersModule.Getter('loggedInUser')
  loggedInUser!: User;

  @appModule.Getter('isLoading')
  isLoading!: (id: string) => boolean;

  @appModule.Mutation('addLoader')
  addLoader!: (id: string) => void;

  @appModule.Mutation('removeLoader')
  removeLoader!: (id: string) => void;

  @projectsModule.Action('fetchAll')
  fetchAllProjects!: (filter?: Filter) => Promise<Project[]>;

  @usersModule.Action('fetchAll')
  fetchUsers!: (filter?: Filter) => Promise<User[]>;

  @assetsModule.Action('fetchAll')
  fetchAssets!: (filter?: Filter) => Promise<Asset[]>;

  formElements: FormElement[][] = [];

  @Watch('personalKey.project')
  async onProjectChanged(newValue: string) {
    this.addLoader('personalKeyForm');
    const assetProjectFilter = new Filter([
      new FilterOperation('project', newValue),
      new FilterOperation('limit', 500),
    ]);
    const userProjectFilter = new Filter([
      new FilterOperation('projects', [newValue]),
      new FilterOperation('limit', 500),
    ]);
    await this.fetchAssets(assetProjectFilter);
    await this.fetchUsers(userProjectFilter);
    for (const outerEl of this.formElements) {
      for (const el of outerEl) {
        if (el.field === 'asset') {
          el.options.optionsData = this.assets;
        }
        if (el.field === 'user') {
          el.options.optionsData = this.users;
        }
      }
    }
    this.removeLoader('personalKeyForm');
  }

  async created() {
    this.addLoader('personalKeyForm');
    await this.fetchAllProjects();
    await this.fetchUsers();
    await this.fetchAssets();
    this.formElements.push([
      new FormElement(
        'project',
        'Project',
        new FormElementOptions(this.projects, 'name', '_id'),
        '',
        FormElementType.SELECT,
      ),
    ]);

    this.formElements.push(
      [
        new FormElement(
          'user',
          'User',
          new FormElementOptions(this.users, 'email', '_id'),
          'required',
          FormElementType.SELECT,
        ),
      ],
      [
        new FormElement(
          'asset',
          'Asset',
          new FormElementOptions(this.assets, 'name', '_id'),
          'required',
          FormElementType.SELECT,
        ),
      ],
    );
    this.removeLoader('personalKeyForm');
  }
}

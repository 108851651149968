




























import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { BModal } from 'bootstrap-vue';

import PersonalKeysTable from '../components/PersonalKeysTable.vue';
import BaseHeader from '../components/BaseHeader.vue';
import PersonalKeyForm from '../components/PersonalKeyForm.vue';

import { Booking } from '../api/bookings/booking.model';
import { SortOrder } from '../shared/types/sort-order.enum';
import { Filter } from '../shared/types/filter.class';
import { Role } from '@/api/auth/role.enum';
import { User } from '@/api/users/user.class';
import { BookingExport } from '@/api/booking-exports/booking-export.model';
import moment from 'moment';
import { PersonalKey } from '@/api/personal-keys/personal-key.model';

const personalKeysModule = namespace('personalKeys');
const usersModule = namespace('users');

// The @Component decorator indicates the class is a Vue component
@Component({
  components: { PersonalKeysTable, BaseHeader, PersonalKeyForm },
})
export default class PersonalKeys extends Vue {
  isLoading = false;
  showDetailModal = false;
  filter = new Filter([], { createdAt: SortOrder.Desc });
  personalKeyForDetail: PersonalKey = new PersonalKey();

  @usersModule.Getter('loggedInUser')
  loggedInUser!: User;

  @personalKeysModule.Action('create')
  create!: (pk: PersonalKey) => Promise<PersonalKey>;

  openCreateModal() {
    this.personalKeyForDetail = new PersonalKey();
    this.showDetailModal = true;
  }

  openDetailModal(personalKey: PersonalKey) {
    this.personalKeyForDetail = personalKey;
    this.showDetailModal = true;
  }

  async doCreate(pk: PersonalKey) {
    this.isLoading = true;
    await this.create(pk);
    this.filter.sort = { ...this.filter.sort };
    this.isLoading = false;
  }
}

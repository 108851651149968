import { BaseModel } from '@/shared/types/base.model';
import { User } from '../users/user.class';
import { Asset } from '../assets/asset.model';

export class PersonalKey extends BaseModel {
  constructor(
    public user?: User,
    public asset?: Asset,
    public project?: string,
  ) {
    super();
  }
}
